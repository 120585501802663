import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const statesOfMexico = [
    "Aguascalientes",
    "Baja California Sur",
    "Baja California",
    "Campeche",
    "Chiapas",
    "Chihuahua",
    "Ciudad de México",
    "Coahuila",
    "Colima",
    "Durango",
    "Estado de México",
    "Guanajuato",
    "Guerrero",
    "Hidalgo",
    "Jalisco",
    "Michoacán",
    "Morelos",
    "Nayarit",
    "Nuevo León",
    "Oaxaca",
    "Puebla",
    "Querétaro",
    "Quintana Roo",
    "San Luis Potosí",
    "Sinaloa",
    "Sonora",
    "Tabasco",
    "Tamaulipas",
    "Tlaxcala",
    "Veracruz",
    "Yucatán",
    "Zacatecas",
];

const SelectMexicoStates = ({ onChange, value }) => {
    return (
        <FormControl fullWidth>
            {/* <InputLabel id="mexico-states-label">Seleccione un es</InputLabel> */}
            <Select
                labelId="mexico-states-label"
                id="mexico-states"
                value={value}
                label="Seleccione un estado"
                onChange={onChange}
            >
                <MenuItem value="">
                    <em>--Seleccione un estado--</em>
                </MenuItem>
                {statesOfMexico.map((state) => (
                    <MenuItem key={state} value={state}>
                        {state}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectMexicoStates;
